import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "../../../assets/icons";
const Input = ({
  placeholder,
  name,
  value,
  onChange,
  type,
  className,
  error,
  errorMsg,
  disabled,
  startContent,
}) => {
  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleShowPassword = () => {
    setVisiblePassword(!visiblePassword);
  };
  return (
    <div className="relative h-12">
      {startContent && (
        <div className="absolute top-0 left-1">{startContent}</div>
      )}
      <input
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={
          type === "password" ? (visiblePassword ? "text" : "password") : type
        }
        className={`${className} w-full h-9 ${
          type === "password" ? "pl-3 pr-8" : "px-3"
        } text-gray-700 placeholder-gray-500 bg-white border ${
          error ? "border-error00" : "border-neutral-300"
        } rounded-lg focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300`}
      />
      {type === "password" && (
        <div
          className="absolute top-2 right-2 cursor-pointer"
          onClick={handleShowPassword}
        >
          {visiblePassword ? <EyeIcon /> : <EyeSlashIcon />}
        </div>
      )}
      {error && <p className="text-error00 pl-3 text-nowrap">{errorMsg}</p>}
    </div>
  );
};

export default Input;
