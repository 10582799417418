const RadioButton = ({name, id, children, onChange, value, checked}) => {
    return (
        <div className="flex items-center">
                    <label
                      className="relative flex items-center cursor-pointer"
                      htmlFor={id}
                    >
                      <input
                        name={name}
                        type="radio"
                        onChange={onChange}
                        value={value}
                        checked={checked}
                        className="peer h-4 w-4 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                        id={id}
                      />
                      <span className="absolute bg-error00 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
                    </label>
                    <label
                      className="ml-2 text-black cursor-pointer font-normal"
                      htmlFor={id}
                    >
                      {children}
                    </label>
                  </div>
    )
}


export default RadioButton;