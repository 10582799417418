const Button = ({onClick, children, disabled}) => {
    return (
        <div className="tailwinf-enabled">
        <button
        disabled={disabled}
        onClick={onClick}
        className="bg-primary00 text-white px-2 py-2.5 flex items-center justify-center rounded-md w-full hover:bg-primary00/90 cursor-pointer"
        >
            {children}
            
        </button>
        </div>
    )
}

export default Button;