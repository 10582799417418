import { useRef, useState } from "react";
import endPoints from "../../../redux/constants";
import axios from "../../../redux/http";

const Modal = ({ audioAnalysisResult, isLoading, errorMsg, onClick }) => {
  console.log("🚀 ~ Modal ~ audioAnalysisResult:", audioAnalysisResult)
  return (
    <div className="popup">
      <div className="modal-content">
        {isLoading ? (
          <div className="main-loading" style={{ height: "20vh" }}>
            <span className="loader"></span>
          </div>
        ) : (
          <div className="main-result">
            <div className="popup-heading">
              <span>
                {errorMsg?.length > 0 ? "Error" : "Audio Analysis Result"}
              </span>
              <button onClick={onClick}>X</button>
            </div>

            <div className="result">
              <span
                style={{
                  color: errorMsg?.length > 0 ? "red" : "",
                  fontSize: "16px",
                }}
              >
                {audioAnalysisResult?.length > 0
                  ? audioAnalysisResult
                  : errorMsg}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const AudioAnalyzer = () => {
  //states
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  //reference
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null); // Reference to the media stream
  const [voice, setVoice] = useState("");
  const [formData, setFormData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [audioAnalysisResult, setAudioAnalysisResult] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handel start recording
  const handleStartRecording = async () => {
    setAudioUrl("");
    setVoice("");
    try {
      // Request access to the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream; // Store the stream reference

      // Create a new MediaRecorder instance
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      // Start recording
      mediaRecorder.start();
      setIsRecording(true);

      // Collect audio data
      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      // Handle the end of the recording
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/mp3",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        let audio = audioUrl.split("3000/");
        setAudioUrl(audioUrl);
        let audioFile = new File([audioBlob], audio[1] + ".mp3", {
          type: "audio/mpeg",
        });
        setFormData(audioFile);
        mediaRecorder.current = null;
      };
      setTimeout(() => {
        handleStopRecording();
      }, 11000);

      //stop recording after ten seconds
    } catch (err) {
      console.error("Error accessing the microphone:", err);
    }
  };
  // Handel stop recording
  const handleStopRecording = (stop) => {
    if (mediaRecorderRef.current) {
      setIsRecording(false);
      mediaRecorderRef.current.stop();

      // Stop all tracks of the media stream
      if (streamRef.current) {
        setVoice("voice");
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null; // Clear the stream reference
      }
    }
  };
  // handel upload/sumbit audio
  const handelUploadAudio = () => {
    let formDataPayload = new FormData();
    formDataPayload.append("audio_file", formData);
    formDataPayload.append("audio_type", 'voice');
    console.log("formdata", formDataPayload);
    setIsLoading(true);
    axios
      .post(endPoints.analyzeAudio, formDataPayload)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        setIsLoading(false);
        setAudioAnalysisResult(response?.data?.data?.ai_assistant_response_of_audio);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.error?.length > 0) {
          setErrorMsg(error?.response?.data?.error);
        } else {
          setErrorMsg("Something wents wrong");
        }
        console.log("🚀 ~ axios.post ~ error:", error);
      });
    setShowModal(true);
  };
  const handelCloseModal = () => {
    setShowModal(false);
    setErrorMsg("");
    if (audioAnalysisResult?.length > 0) {
      setAudioUrl("");
      setVoice("");
      setAudioAnalysisResult("");
    }
  };
  return (
    <div className="voice-analyzer">
      {/* Header */}
      <div className="voice-analyzer-header">
        <p>Voice Deepfake Analyzer</p>
      </div>
      <div className="voice-analyzer-main">
        {/* Audio Recording Section */}
          <div className="voice-analyzer-record">
            <p>Record audio</p>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <button
                className="btn"
                onClick={
                  isRecording ? handleStopRecording : handleStartRecording
                }
              >
                {isRecording ? "Stop Recording" : "Start Recording"}
              </button>
              {isRecording && (
                <img
                  src={require("../../../assets/icons/icons8-audio-wave.gif")}
                  alt="music-gif"
                  width={37}
                  height={37}
                />
              )}
            </div>
            {audioUrl && (
              <div>
                <p style={{ fontWeight: 600 }}>Recorded Audio:</p>
                <audio controls src={audioUrl} />
              </div>
            )}
          </div>
        {/* Upload Audio File */}
        <div className="voice-analyzer-upload">
          <button
            className="btn"
            disabled={voice?.length > 0 ? false : true}
            onClick={handelUploadAudio}
          >
            Upload Audio
          </button>
        </div>
      </div>
      {showModal && (
        <Modal
          audioAnalysisResult={audioAnalysisResult}
          isLoading={isLoading}
          errorMsg={errorMsg}
          onClick={handelCloseModal}
        />
      )}
    </div>
  );
};

export default AudioAnalyzer;
