import * as React from "react";
const EyeIcon = (props) => (
  <svg
    fill="#000000"
    width="22px"
    height="22px"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"eye-solid"}</title>
    <path
      d="M33.62,17.53c-3.37-6.23-9.28-10-15.82-10S5.34,11.3,2,17.53L1.72,18l.26.48c3.37,6.23,9.28,10,15.82,10s12.46-3.72,15.82-10l.26-.48ZM17.8,26.43C12.17,26.43,7,23.29,4,18c3-5.29,8.17-8.43,13.8-8.43S28.54,12.72,31.59,18C28.54,23.29,23.42,26.43,17.8,26.43Z"
      className="clr-i-solid clr-i-solid-path-1"
    />
    <circle
      cx={18.09}
      cy={18.03}
      r={6.86}
      className="clr-i-solid clr-i-solid-path-2"
    />
    <rect x={0} y={0} width={36} height={36} fillOpacity={0} />
  </svg>
);
export default EyeIcon;
