export const validateEmail = (email) => {
  var emailRegex = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return emailRegex.test(email);
};

export const inputValidator = (text, regex) => {
  var numberRegex = /^[0-9]*$/;
  var phoneRegex = /^(\+?[0-9]*)?$/;

  if (regex === "number") {
    return numberRegex.test(text);
  }
  if (regex === "phone") {
    return phoneRegex.test(text);
  } else return true;
};
