import { CrossIcon } from "../../../assets/icons";

const Modal = ({ children, title, close, backButton, backButtonClick }) => {
  return (
    <div className="tailwind-enabled">
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-black/40 transition-opacity"
          aria-hidden="true"
        ></div>

        <div className="fixed inset-0 z-10 w-full min-w-[480px]">
          <div className="flex min-h-full justify-center text-center items-center p-4">
            <div className="absolute py-6 px-6 flex min-h-56 flex-col transform overflow-auto rounded-lg bg-white text-left shadow-xl transition-all w-full mx-4 max-w-lg">
              <div className="flex justify-between">
                <div className="text-lg font-medium flex gap-2 items-center">
                  {backButton && 
                  <div onClick={backButtonClick} className="cursor-pointer">{backButton}</div>
                  }
                  <span>{title ? title : "Alert"}</span>
                </div>
                <div className="cursor-pointer" onClick={close}>
                  <CrossIcon />
                </div>
              </div>
              <div className="pt-3">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
