import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png'
import { Input, Spinner } from '../../../components/shared';
import { useState } from 'react';
import { validateEmail } from '../../../utils/validator';
import axios from 'axios';
import endPoints from '../../../redux/constants';
import { useDispatch } from 'react-redux';
import { currentUser } from '../../../redux/reducers';

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })
    const[emptyData, setEmptyData] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')

    // Functions
    // Check Empty Fields
    const isEmptyData = () => {
        let emptyFields = {};
        Object.entries(loginData)?.forEach(([key, item]) => {
            if (item === null || item === '' || item === undefined) {
                emptyFields = {
                    ...emptyFields,
                    [key]: true,
                };
            }
        });
        if(!validateEmail(loginData?.email)){
            emptyFields = {
                ...emptyFields,
                invalidEmail: true
            }
        }
        setEmptyData(emptyFields)
        return Object?.keys(emptyFields)?.length > 0
    }
    // Handle Input Change
    const handleInputChange = (e) => {
        const {value, name} = e.target;
        setLoginData({
            ...loginData,
            [name] : value
        })
        setEmptyData({
            ...emptyData,
            [name] : false,
            invalidEmail: name === 'email' ? false : emptyData?.invalidEmail
        })
        setError('')
    }
    // Hanlde Login
    const handleLogin = () => {
        if(!isEmptyData()){
            const payload = {
                is_admin: true,
                email: loginData?.email,
                password: loginData?.password
            }
            setLoading(true)
            axios.post(endPoints.loginUser, payload).then((res) => {
                setLoading(false)
                dispatch(currentUser(res?.data?.data))
                navigate('/home')
                console.log("🚀 ~ axios.post ~ res:", res)
            }).catch((error) => {
                setError(error?.response?.data?.message)
                setLoading(false)
                console.log("🚀 ~ axios.post ~ error:", error)
            })
        }
        else{
            setLoading(false)
            console.log('error in login')
        }
    }

return (
    <div className='tailwind-enabled'>
    <div className="w-full h-lvh flex items-center justify-center">
    <div className="w-full max-w-md mx-auto overflow-hidden rounded-lg shadow-md bg-white">
    {
            error &&
            <p className='text-error00 text-center text-base pt-4 pb-2 px-2'>{error}</p>
        }
    <div className='px-4 pt-6 pb-4'>
        <div className="flex justify-center mx-auto">
            <img className="w-auto h-7 sm:h-8" src={logo} alt="" />
        </div>

        <h3 className="mt-3 text-xl font-medium text-center text-gray-600 dark:text-primary00">Welcome Back!</h3>

        <div className='flex flex-col items-center justify-center w-full'>
            <div className="flex flex-col gap-2 items-center justify-center w-full mt-6">
                <div className='w-full flex flex-col gap-3'>
                <Input 
                name={'email'}
                type={'email'}
                value={loginData?.email}
                placeholder={'Email'}
                onChange={handleInputChange}
                error={emptyData?.email ? true : emptyData?.invalidEmail ? true : false}
                errorMsg={emptyData?.email ? 'Email is required' : emptyData?.invalidEmail ? 'Email is invalid' : ''}
                />
                <Input 
                name={'password'}
                type={'password'}
                value={loginData?.password}
                placeholder={'Password'}
                onChange={handleInputChange}
                error={emptyData?.password ? true : false}
                errorMsg={emptyData?.password ? 'Password is required' : ''}
                />
                </div>
                <button className="w-24 h-10 text-sm mt-4 items-end font-medium tracking-wide cursor-pointer text-white border-none capitalize transition-colors duration-300 transform bg-primary00 rounded-lg hover:bg-primary00 focus:outline-none focus:ring focus:ring-primary00 focus:ring-opacity-50"
                onClick={handleLogin}
                disabled={loading}
                >
                    {
                        loading ?
                        <Spinner />
                        :
                        'Log in'
                    }
                </button>
            </div>
        </div>
    </div>

    <div className="flex items-center justify-center py-4 text-center bg-gray-50">
        <span className="text-sm text-gray-600 dark:text-gray-500">Don't have an account? </span>

        <p href="#" className="mx-2 text-sm font-bold text-primary00 hover:underline cursor-pointer" onClick={() => {navigate('/signup')}}>Register</p>
    </div>
</div>
    </div>
    </div>
)
}

export default Login;