// reducers.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: null
};

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        currentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        logout: (state, action) => {
            state.currentUser = null
        }
    },
});

export const { currentUser } = currentUserSlice.actions;
export const { logout } = currentUserSlice.actions;
export default currentUserSlice.reducer;
