import { useNavigate } from 'react-router-dom';
import '../../../index.css'
import logo from '../../../assets/images/logo.png'
import { Input, Spinner } from '../../../components/shared';
import { useState } from 'react';
import { validateEmail } from '../../../utils/validator';
import axios from 'axios';
import endPoints from '../../../redux/constants';
const SignUp = () => {
    const navigate = useNavigate()
    const [signupData, setSignupData] = useState({
        username: '',
        email: '',
        password: ''
    })
    const [emptyData, setEmptyData] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');

    // Functions
    // Handle Empty Data
    const isEmptyData = () => {
        let emptyFields = {};
        Object.entries(signupData)?.forEach(([key, item]) => {
            if (item === null || item === '' || item === undefined) {
                emptyFields = {
                    ...emptyFields,
                    [key]: true,
                };
            }
        });
        if(!validateEmail(signupData?.email)){
            emptyFields = {
                ...emptyFields,
                invalidEmail: true
            }
        }
        setEmptyData(emptyFields)
        return Object?.keys(emptyFields)?.length > 0
    }
    // Handle Input Chnage
    const handleInputChange = (e) => {
        const {value, name} = e.target;
        setSignupData({
            ...signupData,
            [name] : value
        })
        setEmptyData({
            ...emptyData,
            [name] : false,
            invalidEmail: name === 'email' ? false : emptyData?.invalidEmail
        })
        setError('')
    }
    // Handle Signup
    const handleSignup = () => {
        if(!isEmptyData()){
            const payload = {
                first_name: signupData?.username,
                email: signupData?.email,
                password: signupData?.password
            }
            setLoading(true)
            axios.post(endPoints?.registerUser, payload).then((res) => {
                console.log("🚀 ~ axios.post ~ res:", res)
                setLoading(false);
                navigate('/login')
            }).catch((error) => {
                console.log("🚀 ~ axios.post ~ error:", error)
                setError(error?.response?.data?.message[0]?.email || error?.response?.data?.message[0]?.password)
                setLoading(false)
            })
        }
        else {
            console.log('error')
        }
    }

return (
    <div className='tailwind-enabled'>
    <div className="w-full h-lvh flex items-center justify-center">
    <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-md">
        {
            error &&
            <p className='text-error00 text-center text-base pt-4 px-2'>{error}</p>
        }
    <div className="px-4 pt-6 pb-4">
        <div className="flex justify-center mx-auto">
            <img className="w-auto h-7 sm:h-8" src={logo} alt="" />
        </div>

        <h3 className="mt-3 text-xl font-medium text-center text-gray-600 dark:text-primary00">Create New Account</h3>

        <div className='flex flex-col'>
            <div className="flex flex-col gap-2 items-center justify-center mt-6">
                <div className='w-full flex flex-col gap-3'>
                <Input 
                name={'username'}
                type={'text'}
                value={signupData?.username}
                placeholder={'Username'}
                onChange={handleInputChange}
                error={emptyData?.username ? true : false}
                errorMsg={emptyData?.username ? 'Username is required' : ''}
                />
                <Input 
                name={'email'}
                type={'email'}
                value={signupData?.email}
                placeholder={'Email'}
                onChange={handleInputChange}
                error={emptyData?.email ? true : emptyData?.invalidEmail ? true : false}
                errorMsg={emptyData?.email ? 'Email is required' :  emptyData?.invalidEmail ? 'Email is invalid' : ''}
                />
                <Input 
                name={'password'}
                type={'password'}
                value={signupData?.password}
                placeholder={'Password'}
                onChange={handleInputChange}
                error={emptyData?.password ? true : false}
                errorMsg={emptyData?.password ? 'Password is required' : ''}
                />
                </div>
                <button className="w-32 h-10 text-sm  mt-3 items-end font-medium tracking-wide cursor-pointer text-white border-none capitalize transition-colors duration-300 transform bg-primary00 rounded-lg hover:bg-primary00 focus:outline-none focus:ring focus:ring-primary00 focus:ring-opacity-50"
                onClick={handleSignup}
                disabled={loading}
                >
                    {
                        loading ?
                        <Spinner />
                        :
                        'Get Started'
                    }
                </button>
            </div>
        </div>
    </div>

    <div className="flex items-center justify-center py-4 text-center bg-gray-50">
        <span className="text-sm text-gray-600 dark:text-gray-500">Already have an account? </span>
        <p href="#" className="mx-2 text-sm font-bold text-primary00 hover:underline cursor-pointer" onClick={() => {navigate('/login')}}>SignIn</p>
    </div>
</div>
    </div>
    </div>
)
}

export default SignUp;