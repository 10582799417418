const CheckBox = ({ className, value, onChange, id }) => {
  return (
    <div className="relative">
      <input
        checked={value}
        onChange={onChange}
        type="checkbox"
        id={id}
        className="peer hidden"
      />
      <label
        htmlFor={id}
        className={`block w-[18px] h-4 bg-error00 ${className} rounded-[4px] border border-neutral-300 cursor-pointer  peer-checked:before:content-['✔'] peer-checked:before:text-neutral-200 peer-checked:before:absolute peer-checked:before:inset-0 peer-checked:before:flex peer-checked:before:items-center peer-checked:before:justify-center`}
      ></label>
    </div>
  );
};

export default CheckBox;
