import React, { useEffect, useState } from "react";
import AddUserModal from "../../../components/UserEngagement/AddUserModal";
import Table from "../../../components/UserEngagement/Table";
import axios from "../../../redux/http";
import endPoints from "../../../redux/constants";
import { Button, SearchBar } from "../../../components/shared";
import { AddUserIcon } from "../../../assets/icons";

const UserEngagementContent = () => {
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    expirationTime: "",
    expirationTimeChoice: "minutes",
    appInstallInstruction: true,
    activationLink: true,
    deviceType: "Android",
    plateformOS: "",
    deviceModel: "",
  });
  const [rows, setRows] = useState([]);
  const [searchResult, setSearchResult] = useState([])
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const togglePopup = () => {
    setOpenModal(!openModal);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    const searchResult = rows?.filter((item) => (item?.full_name.toLowerCase().includes(value?.toLowerCase())))
    setSearchResult(searchResult);
    if(value?.length === 0){
      setSearchResult([])
    }
  };

  const getUserListing = async () => {
    setLoading(true);
    axios
      .get(endPoints.addNewUser)
      .then((res) => {
        setRows(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("🚀 ~ axios.get ~ err:", err);
      });
  };
  useEffect(() => {
    getUserListing();
  }, []);

  return (
    <div className="tailwind-enabled">
      <div className="relative flex flex-col gap-3">
        <p className="font-semibold text-3xl">User Management</p>
        <div className="flex justify-between w-full gap-3 flex-wrap">
          <div className="w-[30rem]">
            <SearchBar
              placeholder={"Search Users"}
              value={searchValue}
              onChange={handleSearch}
              onCrossClick={() => {
                setSearchValue('')
                setSearchResult([])
              }}
            />
          </div>
          <Button onClick={togglePopup}>
            <div className="flex gap-2 px-2">
              <span>Add User</span>
              <AddUserIcon />
            </div>
          </Button>
        </div>
        <Table rowData={searchValue?.length > 0 ? searchResult : rows} loading={loading} />

        {openModal && (
          <AddUserModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            userData={userData}
            setUserData={setUserData}
            getUserListing={getUserListing}
            setSearchValue={setSearchValue}
          />
        )}
      </div>
    </div>
  );
};

export default UserEngagementContent;
