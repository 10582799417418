import React from 'react';

const Spinner = ({className}) => {
  return (
    <div className="flex justify-center items-center" style={{
      animation: 'rotation 1s linear infinite'
    }}>
      <div
        className={`w-6 h-6 border-4 border-white border-solid border-t-transparent rounded-full animate-spin ${className}`}
        role="status"
        aria-label="Loading"
      ></div>
    </div>
  );
};

export default Spinner;
