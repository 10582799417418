import { Spinner } from "../../shared";

const Table = ({rowData, loading}) => {
  return (
    <div className="tailwind-enabled">
      <div className="w-full bg-white/50 py-3 px-6 rounded-t-xl border-t border-x flex justify-between font-medium text-[12px] text-text00 gap-2">
        <p className="w-[33%]">Employee</p>
        <p className="w-[33%]">Device Type</p>
        <p className="w-[33%]">Email</p>
      </div>
      <div className="min-h-80 bg-white border-x border-b rounded-b-xl overflow-hidden">
        {
          loading ?
          <div className="bg-white flex justify-center items-center min-h-80 border-t">
            <Spinner 
            className={'!border-primary00 !border-t-transparent'}
            />
            </div>
          :
          rowData?.length > 0 ?
          rowData?.map((row) => {
            return (
              <div key={row?.id} className="w-full bg-white py-5 px-6 border-t flex justify-between text-sm gap-2">
              <p className="w-[33%] text-text01 truncate font-medium">{row?.full_name}</p>
              <p className="w-[33%] text-text00 truncate">{row?.user_active_device[0]?.device_type}</p>
              <p className="w-[33%] text-text00 truncate">{row?.email}</p>
              </div>
            )
          })
          :
          <div className="min-h-80 w-full flex justify-center items-center text-base font-semibold">No data found</div>
        }
      </div>
    </div>
  );
};

export default Table;
 