import activeFinding from '../../../assets/icons/activefindings.svg';
import usersEngagedMonth from "../../../assets/icons/usersEngagedMonth.svg";
import calls from "../../../assets/icons/calls.svg";

const getProgressColor = (value) => {
    if (value <= 0.4) return 'green';
    if (value >= 0.6) return 'red';
    return 'defaultColor';
};

const HomeContent = () => (
            <div>
                <h1>Dashboard</h1>
                    <div id="topboxes">
                        <div className="topcontent">
                            <div className="title">
                                <img src={activeFinding} alt="activeFindings"></img>
                                <h4>Alerts</h4>
                            </div>
                            <p style={{marginTop: 26, fontSize: 30, fontWeight: 600}}>3</p>
                        </div>
                        <div className="topcontent">
                        <div className="title">
                                <img src={calls} alt="activeFindings"></img>
                                <h4>High Risk Calls</h4>
                        </div>
                        <p style={{marginTop: 26, fontSize: 30, fontWeight: 600}}>10</p>
                        </div>
                        <div className="topcontent">
                        <div className="title">
                            <img src={usersEngagedMonth} alt="activeFindings"></img>
                            <h4>Calls With AI Presence</h4>
                        </div>
                        <p style={{marginTop: 26, fontSize: 30, fontWeight: 600}}>5</p>
                        </div>
                    </div>

                    <div id="recent">
                        <h3>Recent Alerts</h3>
                        <table className='findingTable'>
                            <thead>
                                <tr id="tablelabel">
                                    <th>Date & Time</th>
                                    <th>Integration Type</th>
                                    <th>Employee</th>
                                    <th>Sender Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Date & Time</td>
                                    <td>Phishing</td>
                                    <td>Gmail</td>
                                    <td>
                                        <div className="scoreBar">
                                            <div className="progress-wrapper">
                                                <div className={`progress-bar ${getProgressColor(0.5)}`} style={{ width: '50%' }}></div>
                                            </div>
                                            <p>3</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date & Time</td>
                                    <td>Phishing</td>
                                    <td>Gmail</td>
                                    <td className="bartd">
                                        <div className="scoreBar">
                                            <div className="progress-wrapper">
                                                <div className={`progress-bar ${getProgressColor(1)}`} style={{ width: '100%' }}></div>
                                            </div>
                                            <p>5</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
    </div>
);

export default HomeContent;