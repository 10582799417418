// BaseURL
export const baseURL = process.env.REACT_APP_API_ENDPOINT;

// EndPoints
const endPoints = {
    // Email Analyzer
    getEmails: `${baseURL}fetch_emails`,

    analyzeEmail: `${baseURL}analyze_email`,

    analyzeAudio: `${baseURL}audio/analyze_audio/`,

    loginUser: `${baseURL}users/login/`,

    registerUser: `${baseURL}users/register/`,

    addNewUser: `${baseURL}users/admin/`,
}


export default endPoints;