import { useState } from "react";
import { Button, CheckBox, RadioButton, Spinner } from "../../shared";
import Input from "../../shared/Input";
import Modal from "../../shared/Modal";
import endPoints from "../../../redux/constants";
import axios from "../../../redux/http";
import { inputValidator, validateEmail } from "../../../utils/validator";
import { ArrowIcon } from "../../../assets/icons";

const AddUserModal = ({
  openModal,
  setOpenModal,
  userData,
  setUserData,
  getUserListing,
  setSearchValue,
}) => {
  const [emptyData, setEmptyData] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "expirationTime") {
      if (!inputValidator(value, "number")) return;
    }
    if (name === "phone") {
      console.log(!inputValidator(value, "phone"));
      if (!inputValidator(value, "phone")) return;
    }
    setUserData({
      ...userData,
      [name]: value,
    });
    setEmptyData({
      ...emptyData,
      [name]: false,
      invalidEmail: name === "email" ? false : emptyData?.invalidEmail,
    });
  };
  const isEmptyData = (step) => {
    let emptyFields = {};
    if (step === 1) {
      if (
        userData?.name === "" ||
        userData?.name === undefined ||
        userData?.name === null
      ) {
        emptyFields = {
          ...emptyFields,
          name: true,
        };
      }
      if (
        userData?.email === "" ||
        userData?.email === undefined ||
        userData?.email === null
      ) {
        emptyFields = {
          ...emptyFields,
          email: true,
        };
      }
      if (!validateEmail(userData?.email)) {
        emptyFields = {
          ...emptyFields,
          invalidEmail: true,
        };
      }
    }
    if (step === 2) {
      if (
        userData?.phone === "" ||
        userData?.phone === undefined ||
        userData?.phone === null
      ) {
        emptyFields = {
          ...emptyFields,
          phone: true,
        };
      }
    }
    if (step === 3) {
      if (
        userData?.expirationTime === "" ||
        userData?.expirationTime === undefined ||
        userData?.expirationTime === null
      ) {
        emptyFields = {
          ...emptyFields,
          expirationTime: true,
        };
      }
    }
    setEmptyData(emptyFields);
    return Object.keys(emptyFields)?.length > 0;
  };
  const handleCreateNewUser = () => {
    const payload = {
      first_name: userData?.name,
      email: userData?.email,
      phone_no: userData?.phone,
      app_install_instructions: userData?.appInstallInstruction,
      activation_link_instructions: userData?.activationLink,
      activation_link_exp:
        userData?.expirationTime + " " + userData?.expirationTimeChoice,
      device: {
        platform_os: userData?.plateformOS,
        device_model: userData?.deviceModel,
        device_type: userData?.deviceType,
      },
    };
    setLoading(true);
    axios
      ?.post(endPoints.addNewUser, payload)
      .then((res) => {
        console.log("🚀 ~ axios?.post ~ res:", res);
        setLoading(false);
        handleCloseModal();
        setSearchValue("");
        getUserListing();
      })
      .catch((err) => {
        setError(
          err?.response?.data?.message[0]?.email ||
            err?.response?.data?.message[0]?.non_field_errors ||
            err?.response?.data?.message
        );
        setLoading(false);
        console.log("🚀 ~ axios?.post ~ err:", err);
      });
  };
  const createUser = () => {
    if (!isEmptyData(step)) {
      if (step < 4) {
        setStep((prev) => prev + 1);
      }
      if (step === 4) {
        handleCreateNewUser();
      }
    } else {
      console.log("error");
    }
  };

  const handleCloseModal = () => {
    setUserData({
      name: "",
      email: "",
      phone: "",
      expirationTime: "",
      expirationTimeChoice: "minutes",
      appInstallInstruction: true,
      activationLink: true,
      deviceType: "Android",
      plateformOS: "",
      deviceModel: "",
    });
    setOpenModal(false);
    setError("");
  };

  return (
    <Modal
      setOpenModal={setOpenModal}
      close={handleCloseModal}
      title={
        step === 1
          ? "Create User"
          : step === 2
          ? "Add Phone"
          : step === 3
          ? "Send App Activation"
          : "Send Herd App Instructions"
      }
      backButton={step > 1 && <ArrowIcon className="rotate-180" />}
      backButtonClick={() => {
        setStep((prev) => prev - 1);
      }}
    >
      <div className="min-w-[480px] pr-5 tailwind-enabled">
        {step === 1 ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <label className="">Name</label>
              <Input
                name={"name"}
                placeholder={"Enter user's name"}
                value={userData?.name}
                onChange={handleInputChange}
                error={emptyData?.name ? true : false}
                errorMsg={emptyData?.name ? "Name is required" : ""}
                type={"text"}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label>Email</label>
              <Input
                name={"email"}
                placeholder={"Enter user's email"}
                value={userData?.email}
                onChange={handleInputChange}
                error={
                  emptyData?.email
                    ? true
                    : emptyData?.invalidEmail
                    ? true
                    : false
                }
                errorMsg={
                  emptyData?.email
                    ? "Email is required"
                    : emptyData?.invalidEmail
                    ? "Email is invalid"
                    : ""
                }
                type={"email"}
              />
            </div>
            <div className="pt-3">
              <Button onClick={createUser}>Create</Button>
            </div>
          </div>
        ) : step === 2 ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <label className="">Phone</label>
              <Input
                name={"phone"}
                placeholder={"+991234567890"}
                value={userData?.phone}
                onChange={handleInputChange}
                error={emptyData?.phone ? true : false}
                errorMsg={emptyData?.phone ? "Phone is required" : ""}
                type={"text"}
              />
            </div>
            <div className="mt-3">
              <Button onClick={createUser}>Add Phone</Button>
            </div>
          </div>
        ) : step === 3 ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <label className="">Phone</label>
              <Input
                name={"phone"}
                value={userData?.phone}
                onChange={handleInputChange}
                error={false}
                errorMsg={""}
                disabled={true}
                type={"text"}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="">Expiration</label>
              <div className="flex gap-2">
                <Input
                  name={"expirationTime"}
                  placeholder={""}
                  value={userData?.expirationTime}
                  onChange={handleInputChange}
                  error={emptyData?.expirationTime ? true : false}
                  errorMsg={emptyData?.expirationTime ? "Time is required" : ""}
                  type={"text"}
                />
                <div className="w-full">
                  <select
                    className="w-full border rounded-md appearance-none h-9 border-neutral-300 px-2 outline-none  focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        expirationTimeChoice: e.target.value,
                      });
                    }}
                  >
                    <option value={"hours"}>Hours</option>
                    <option value={"minutes"}>Minutes</option>
                    <option value={"days"}>Days</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="pt-3">
              <Button onClick={createUser}>Generate Activation Link</Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex justify-between border-b-2 pb-2">
              <p className="text-md font-medium">Phone Number</p>
              <p className="text-md font-medium text-blue-700">
                {userData?.phone}
              </p>
            </div>
            <div className="pt-2 flex flex-col gap-4">
              <div className="flex gap-28">
                <p className="text-md font-medium">Device type</p>
                <div className="flex flex-col">
                  <div className="flex flex-col gap-2">
                    <RadioButton
                      value={"Android"}
                      checked={userData?.deviceType === "Android"}
                      name={"deviceType"}
                      id={"Android"}
                      onChange={handleInputChange}
                    >
                      Android
                    </RadioButton>
                    <RadioButton
                      value={"IPHONE"}
                      checked={userData?.deviceType === "IPHONE"}
                      name={"deviceType"}
                      id={"IPHONE"}
                      onChange={handleInputChange}
                    >
                      iPhone
                    </RadioButton>
                  </div>
                </div>
              </div>
              <div className="flex gap-12">
                <p className="text-md font-medium text-nowrap">
                  App Install Instruction
                </p>
                <div>
                  <div className="flex gap-2 items-center pb-1">
                    <CheckBox
                      className={"!bg-blue-600"}
                      value={userData?.appInstallInstruction}
                      id={"appInstallInstruction"}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          appInstallInstruction: e.target.checked,
                        });
                      }}
                    />
                    <p className="text-nowrap">
                      Send App Install Instructions via SMS
                    </p>
                  </div>
                  <div className="bg-[#D9D9D9] rounded-md p-3 w-[91%]">
                    <p>
                      Welcome To Herd! Install The Herd App via Google Play
                      Store.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-24">
                <p className="text-md font-medium text-nowrap">
                  Activation Link
                </p>
                <div>
                  <div className="flex gap-2 items-center pb-1">
                    <CheckBox
                      value={userData?.activationLink}
                      id={"activationLink"}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          activationLink: e.target.checked,
                        });
                      }}
                    />
                    <p className="text-nowrap">
                      Send Activation Instructions via SMS
                    </p>
                  </div>
                  <div className="bg-[#D9D9D9] rounded-md p-3 w-[91%]">
                    <p>
                      After Installation, Activate App By Clicking The Link.
                    </p>
                  </div>
                </div>
              </div>
              {error && <p className="text-error00 text-base">{error}</p>}
              <Button onClick={createUser} disabled={loading}>
                {loading ? <Spinner /> : "Send Instructions Via SMS"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddUserModal;
