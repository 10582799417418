import { useSelector } from "react-redux"
import { setAuthToken } from "../redux/http"

export const useIsAuthenticatedUser = () => {
    let user = useSelector((state) => state?.currentUser?.currentUser)
    if(user?.token?.access){
        setAuthToken(user?.token?.access)
        return true
    }
    else return false
    
}