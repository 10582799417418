import { CrossIcon, SearchIcon } from "../../../assets/icons";

const SearchBar = ({ value, onChange, placeholder, onCrossClick }) => {
  return (
    <div className="relative">
      <div className="absolute top-2.5 left-1.5">
        <SearchIcon />
      </div>
      {
        value?.length > 0 &&
        <div className="absolute right-1.5 top-[9px] cursor-pointer" onClick={onCrossClick}>
            <CrossIcon />
        </div>
      }
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder || 'Search'}
        className="py-2 rounded-md border w-full px-7 flex items-center outline-none focus:border-blue-400 hover:border-blue-400"
      />
    </div>
  );
};

export default SearchBar;
