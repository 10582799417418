// import DashBoard from "./panels/UserPanel/Dashboard";
import Router from "./router";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
    <main className="column">
      <Router />
    </main>
      </BrowserRouter>
  );
}

export default App;
