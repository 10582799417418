import Android from "../../../assets/icons/Android";
import IOS from "../../../assets/icons/IOS";
import Amazon from "../../../assets/icons/Amazon";
import Herd from "../../../assets/icons/Herd";

const IntegrationsContent = () => (
    <div id="inteContent">
        <h1>Integrations</h1>
        <h3>Mobile Devices</h3>
        <div className="flexcontainer">
            <div className="inteContainer">
                <div className="leftcontent">
                    <Android style={{minWidth: '70px', minHeight: "70px"}}/>
                    <div className="stack">
                        <h5>Android </h5>
                        <p>Monitor Android Phones For Voice Fraud </p>
                    </div>
                </div>
                <button className="install">Install Plugin</button>
            </div>

            <div className="inteContainer">
                <div className="leftcontent">
                    <IOS style={{minWidth: '70px', minHeight: "70px"}}/>
                    <div className="stack">
                        <h5>iOS</h5>
                        <p>Monitor iPhone’s for Voice Fraud</p>
                    </div>
                </div>
                <button className="install">Install Plugin</button>
            </div>
        </div>
        <h3>VoIP Systems</h3>
        <div className="flexcontainer">
        <div className="inteContainer">
            <div className="leftcontent">
                <Amazon style={{minWidth: '70px', minHeight: "70px"}}/>
                <div className="stack">
                    <h5>AWS Connect</h5>
                    <p>Monitor Inbound Calls Through AWS Connect.</p>
                </div>
            </div>
            <button className="install">Install Plugin</button>
        </div>
        </div>
        <h3>API </h3>
        <div className="flexcontainer">
        <div className="inteContainer">
            <div className="leftcontent">
                <Herd style={{minWidth: '70px', minHeight: "70px"}}/>
                <div className="stack">
                    <h5>Herd API</h5>
                    <p>Access our API To Integrate With Your Own Tools</p>
                </div>
            </div>
            <button className="install">Install Plugin</button>
        </div>
        </div>
    </div>
);

export default IntegrationsContent;
